//== DEFAULTS RAILS
import Rails from "@rails/ujs"
Rails.start()


//== JQUERY
  import $ from 'jquery'
  window.$ = window.jQuery = $

  $(document).ready(function($){
    //
    //
    // MOBILE MENUE
    $('#navbar_main ul li.dropdown > a').click(function(e) {
      if ($(window).width() <= 991.98){
        if ($(this).next('ul').is(':hidden') ) {
          $('#navbar_main ul li.dropdown ul').hide();
          $(this).next('ul').show();
        } else {
          $(this).next('ul').hide();
        }

        e.preventDefault();
      }
    });
  });


//== BOOTSTRAP
  // import { Popper } from '@popperjs/core'
  import { Collapse, Modal } from 'bootstrap'
